$blue: #007bff;
$blue-light: #007bff10;
$blue-hover: #007bff30;
$blue-light-opaque: #eff7ff;
$blue-mid: #007bff99;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$cherry: #ff073a;
$cherry-mid: #ff073a99;
$cherry-light: #ff073a20;
$cherry-hover: #ff073a30;
$cherry-light-opaque: #ffe0e6;
$red: #dc3545;
$red-light: #dc354520;
$red-mid: #dc354599;
$orange: #fd7e14;
$orange-mid: #fd7e1499;
$orange-light: #fd7e1420;
$orange-hover: #fd7e1430;
$orange-light-opaque: #ffefe2;
$yellow: #ffc107;
$yellow-light: #ffc10720;
$yellow-hover: #ffc10730;
$yellow-mid: #ffc10799;
$yellow-light-opaque: #fff7e0;
$green: #28a745;
$green-light: #28a74520;
$green-hover: #28a74530;
$green-mid: #28a74599;
$teal: #20c997;
$cyan: #17a2b8;
$white: #fff;
$gray: #6c757d;
$gray-light: #6c757d10;
$gray-hover: #6c757d20;
$gray-mid: #6c757d99;
$gray-dark: #343a40;
$gray-opaque: #343a4020;
$primary: #007bff;
$secondary: #6c757d;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #343a40;
$purple: #201aa2dd;
$purple-light: #201aa220;
$purple-hover: #201aa230;
$purple-mid: #201aa299;
$purple-light-opaque: #e3e2f3;
