.UnderConstruction {
  text-align: center;
}

.UnderConstruction-logo {
  height: 30vmin;
  
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .UnderConstruction-logo {
  }
}

.UnderConstruction-header {
  /* background-color:white; */
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: '#424572';
}

.UnderConstruction-link {
  color: #424572;
}
/* 
@keyframes UnderConstruction-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
