@font-face {
    font-family: 'Archia';
    src: url('fonts/Archia/archia-thin-webfont.eot');
    src:
      url('fonts/Archia/archia-thin-webfont.eot?#iefix') format('embedded-opentype'),
      url('fonts/Archia/archia-thin-webfont.woff2') format('woff2'),
      url('fonts/Archia/archia-thin-webfont.woff') format('woff'),
      url('fonts/Archia/archia-thin-webfont.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Archia';
    src: url('fonts/Archia/archia-light-webfont.eot');
    src:
      url('fonts/Archia/archia-light-webfont.eot?#iefix') format('embedded-opentype'),
      url('fonts/Archia/archia-light-webfont.woff2') format('woff2'),
      url('fonts/Archia/archia-light-webfont.woff') format('woff'),
      url('fonts/Archia/archia-light-webfont.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Archia';
    src: url('fonts/Archia/archia-regular-webfont.eot');
    src:
      url('fonts/Archia/archia-regular-webfont.eot?#iefix') format('embedded-opentype'),
      url('fonts/Archia/archia-regular-webfont.woff2') format('woff2'),
      url('fonts/Archia/archia-regular-webfont.woff') format('woff'),
      url('fonts/Archia/archia-regular-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Archia';
    src: url('fonts/Archia/archia-medium-webfont.eot');
    src:
      url('fonts/Archia/archia-medium-webfont.eot?#iefix') format('embedded-opentype'),
      url('fonts/Archia/archia-medium-webfont.woff2') format('woff2'),
      url('fonts/Archia/archia-medium-webfont.woff') format('woff'),
      url('fonts/Archia/archia-medium-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Archia';
    src: url('fonts/Archia/archia-semibold-webfont.eot');
    src:
      url('fonts/Archia/archia-semibold-webfont.eot?#iefix') format('embedded-opentype'),
      url('fonts/Archia/archia-semibold-webfont.woff2') format('woff2'),
      url('fonts/Archia/archia-semibold-webfont.woff') format('woff'),
      url('fonts/Archia/archia-semibold-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Archia';
    src: url('fonts/Archia/archia-bold-webfont.eot');
    src:
      url('fonts/Archia/archia-bold-webfont.eot?#iefix') format('embedded-opentype'),
      url('fonts/Archia/archia-bold-webfont.woff2') format('woff2'),
      url('fonts/Archia/archia-bold-webfont.woff') format('woff'),
      url('fonts/Archia/archia-bold-webfont.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }