@import '../../../styles/fonts.scss';
@import '../../../styles/colors.scss';

h1 {
  font-family: 'archia';
  font-size: 24px !important;
  font-weight: 900;
  text-transform: uppercase;
}

h2 {
  font-family: 'archia';
  font-size: 18px !important;
  font-weight: 400;
  text-transform: uppercase;
}

h3 {
  font-family: 'archia';
  font-size: 14px !important;
  font-weight: 400;
  text-transform: uppercase;
}

h4 {
  font-family: 'archia';
  font-size: 13px !important;
  font-weight: 900;
  text-transform: uppercase;
}

h5 {
  font-family: 'archia';
  font-size: 12px !important;
  font-weight: 900;
  text-transform: uppercase;
}

h6 {
  font-family: 'archia';
  font-size: 11px !important;
  font-weight: 600;
  text-transform: uppercase;
}

.summary-stats {
  display: flex;
  flex-direction: row;
  width: 100%;
  // height: 5rem;
  position: relative;
  margin: 0.5rem;
  h1,
  h5,
  h6,
  h3 {
    margin: 0;
  }

  h3 {
    color: $gray-mid;
    font-weight: 900;
  }

  .stats {
    background: $cherry-light;
    color: $cherry;
     padding: 0.5rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
     margin: 0.25rem;
     width: 100%;
    box-shadow: 0 2px 3px gray;

    .stats-bottom {
      display: flex;
      flex-direction: row;

      // h6 {
      //   margin-top: auto;
      //   margin-bottom: 0.25rem;
      // }
    }

    h3 {
      font-weight: 600;
    }

    &.is-green {
      background: $green-light;
      h5,
      h6 { color: $green-mid; }

      h3 { color: $green; }
    }

    &.is-blue {
      background: $blue-light;
      h5,
      h6 { color: $blue-mid; }

      h3 { color: $blue; }
    }

    &.is-gray {
      background: $gray-light;
      h5,
      h6 { color: $gray-mid; }

      h3 { color: $gray; }
    }

    &.is-cherry {
      background: $cherry-light;
      h5,
      h6 { color: $cherry-mid; }

      h3 { color: $cherry; }
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
  .stats-row {
    background: $cherry-light;
    color: $cherry;
     padding: 0.5rem;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
     margin: 0.25rem;
     width: 100%;
    box-shadow: 0 2px 3px gray;

    .stats-bottom {
      display: flex;
      flex-direction: row;

      // h6 {
      //   margin-top: auto;
      //   margin-bottom: 0.25rem;
      // }
    }

    h3 {
      font-weight: 600;
    }

    &.is-green {
      background: $green-light;
      h5,
      h6 { color: $green-mid; }

      h3 { color: $green; }
    }

    &.is-blue {
      background: $blue-light;
      h5,
      h6 { color: $blue-mid; }

      h3 { color: $blue; }
    }

    &.is-gray {
      background: $gray-light;
      h5,
      h6 { color: $gray-mid; }

      h3 { color: $gray; }
    }

    &.is-cherry {
      background: $cherry-light;
      h5,
      h6 { color: $cherry-mid; }

      h3 { color: $cherry; }
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.card-stats {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  // margin: 0.5rem;
  h1,
  h4,
  h5,
  h6,
  h3 {
    margin: 0;
  }

  h3 {
    color: $gray;
    font-weight: 900;
  }

  .stats {
    background: $cherry-light;
    color: $cherry;
    // padding: 0.5rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
     margin: 0.25rem;
     width: 100%;
    box-shadow: 0 2px 3px gray;

    .stats-bottom {
      display: flex;
      flex-direction: row;

      // h6 {
      //   margin-top: auto;
      //   margin-bottom: 0.25rem;
      // }
    }

    h3 {
      font-weight: 600;
    }

    &.is-green {
      background: $green-light;
      h3{ color: $gray; }
      h5,
      h6 { color: $gray; }
      h2 {color:$gray}
      h4 { color: $green; }
    }

    &.is-blue {
      background: $blue-light;
      h3{ color: $gray; }
      h5,
      h6 { color: $gray; }
      h2 {color:$gray}
      h4 { color: $blue; }
    }

    &.is-gray {
      background: $gray-light;
      h3{ color: $gray; }
      h5,
      h6 { color: $gray; }
      h2 {color:$gray}
      h4 { color: $gray; }
    }

    &.is-cherry {
      background: $cherry-light;
      h3{ color: $gray; }
      h5,
      h6 { color: $gray; }
      h2 {color:$gray}
      h4 { color: $cherry; }
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
 
  .stats-row {
    background: $cherry-light;
    color: $cherry;
    // padding: 0.5rem;
    // border-radius: 5px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    
    h1,
    h4,
    h5,
    h6,
    h3 {
      margin: .25rem;
    }
    h3 {
      font-weight: 600;
    }

    &.is-green {
      background: $green-light;
      h3{ color: $gray; }
      h5,
      h6 { color: $gray; }
      h2 {color:$gray}
      h4 { color: $green; }
    }

    &.is-blue {
      background: $blue-light;
      h3{ color: $gray; }
      h5,
      h6 { color: $gray; }
      h2 {color:$gray}
      h4 { color: $blue; }
    }
    &.is-yellow {
      background: $yellow-light;
      h3{ color: $gray; }
      h5,
      h6 { color: $gray; }
      h2 {color:$gray}
      h4 { color: $yellow; }
    }
    &.is-gray {
      background: $gray-light;
      h3{ color: $gray; }
      h5,
      h6 { color: $gray; }
      h2 {color:$gray}
      h4 { color: $gray; }
    }

    &.is-cherry {
      background: $cherry-light;
      h3{ color: $gray; }
      h5,
      h6 { color: $gray; }
      h2 {color:$gray}
      h4 { color: $cherry; }
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.card-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  border-radius: 5px;
  padding: 0.5rem;
  justify-content: center;
    align-items: center;  
   margin-left: 0.5rem;
   margin-right: 0.5rem;
  h1,
  
  h4,
  h5,
  h6,
  h3 {
    margin: 0;
  }

  h3 {
    color: $gray;
    font-weight: 900;
  }

  .stats {
    background: $cherry-light;
    color: $cherry;
     padding: 0.5rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
     margin: 0.25rem;
     width: 100%;
    box-shadow: 0 2px 3px gray;

    h3 {
      font-weight: 600;
    }

    &.is-green {
      background: $green-light;
      h3{ color: $gray; }
      h5,
      h6 { color: $gray; }
      h2 {color:$gray}
      h4 { color: $green; }
    }

    &.is-blue {
      background: $blue-light;
      h3{ color: $gray; }
      h5,
      h6 { color: $gray; }
      h2 {color:$gray}
      h4 { color: $blue; }
    }

    &.is-gray {
      background: $gray-light;
      h3{ color: $gray; }
      h5,
      h6 { color: $gray; }
      h2 {color:$gray}
      h4 { color: $gray; }
    }

    &.is-cherry {
      background: $cherry-light;
      h3{ color: $gray; }
      h5,
      h6 { color: $gray; }
      h2 {color:$gray}
      h4 { color: $cherry; }
    }
    &.is-white {
      background: $white;
      color: $white;
    }
  }

  &:hover {
    background: $gray-light;
    cursor: pointer;
      h3{ color: $blue; }
  }
}